<label [ngClass]="{'filled': value && value.length > 0, 'error-input': error()}">
  @if (label()) {
    <span [ngClass]="{'filled': value && value.length > 0}">{{ label() }}</span>
  }
  <div>
  <input [type]="isPassword() ? 'password' : 'text'" [(ngModel)]="value" [placeholder]="placeholder()" (ngModelChange)="valueChange()" [ngClass]="{'visible' : isVisible, 'withToggle' : isPassword}" (keydown.enter)="handleEnter($event)" (blur)="onBlur()" (input)="inputEvent.emit()">
  <!-- <span [ngClass]="{'placeholder': !value}" data-cursor="|">{{ (value && (!isPassword || (isPassword && isVisible))) ? value : (value ? '*'.repeat(value.length) : placeholder) }}</span> -->

  @if (isPassword()) {
     @if (isVisible && value) {
      <span>{{value}}</span>
    }
    <button type="button" (mousedown)="togglePasswordVisibility()" (dragstart)="preventDrag($event)">
    <img [src]="isVisible ? 'assets/icons/visible.svg' : 'assets/icons/invisible.svg'" alt=""></button>
  }
</div>
</label>
@if(description()) {
  <span class="input-description" [ngClass]="{'error-description': error()}">{{ description() }}</span>
}


<!-- [autocomplete]="isVisible ? 'one-time-code' : 'current-password'" -->
  <!-- (animationstart)="handleAnimationStart($event)" -->
